import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
// import CheckGroup from '../../../components/common/CheckGroup';
import { DataTable, SelectGroup, SubmitForm } from '../../../components/common';
import { paymentEnum, paymentMethod } from '../../../constantsApp';

class PaymentTypes extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      paymentMethods: [],
      paymentValues: t ? t('selections:paymentMethod')() : [],
      selectedItem: {},
      newItem: {},
      applying: false,
    };
    this.lastIndex = 0;
    this.isEmptyListServer = true;
    this.paymentMethodsOrigin = [];
  }

  componentDidMount = () => {
    const { getPaymentConfigByType } = this.props;
    getPaymentConfigByType(paymentEnum.paymentConfig.PAYMENT_METHODS, ({ response }) => {
      this.convertResponsePaymentConfig(response);
    });
  };

  convertResponsePaymentConfig = response => {
    const { t } = this.props;
    if (response.id) this.isEmptyListServer = false;
    if (response && response.paymentMethods && response.paymentMethods.length > 0) {
      this.getLastIndex(response.paymentMethods);
      this.paymentMethodsOrigin = [...response.paymentMethods];

      this.setState({
        paymentMethods: response.paymentMethods,
        paymentValues: t('selections:paymentMethod')().filter(el =>
          response.paymentMethods.every(subEl => subEl.method !== el.value)
        ),
      });
    }
  };

  updateRowValuePaymentTerm = (idx, type, val) => {
    try {
      const { paymentMethods } = this.state;
      const index = paymentMethods.findIndex(e => {
        return e.index === idx;
      });

      paymentMethods[index][type] = val;

      this.setState({
        paymentMethods: [...paymentMethods],
      });
    } catch (error) {
      console.log(error);
    }
  };

  getLastIndex = array => {
    array.forEach(item => {
      if (item && item.index > this.lastIndex) {
        this.lastIndex = item.index;
      }
    });
  };

  onSelectItem = item => {
    let { selectedItem } = this.state;
    if (item.index === selectedItem.index) {
      selectedItem = {};
    } else {
      selectedItem = item;
    }
    this.setState({ selectedItem });
  };

  onChangeNewValue = (type, val) => {
    try {
      const { newItem } = this.state;
      newItem[type] = val;

      this.setState({
        newItem: { ...newItem },
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleApplyBtn = async e => {
    e.preventDefault();
    this.setState({ applying: true });
    const { paymentMethods } = this.state;
    const resultMethods = paymentMethods.map(el => ({
      index: el.index,
      method: el.method,
    }));

    const listRemoveMethod = this.paymentMethodsOrigin
      .filter(el => !resultMethods.some(subEl => subEl.index === el.index))
      .map(el => ({ index: el.index }));
    const dataMethods = {
      paymentConfigType: paymentEnum.paymentConfig.PAYMENT_METHODS,
      paymentMethods: resultMethods.concat(listRemoveMethod),
    };
    if (this.isEmptyListServer) {
      await this.props.createPaymentConfigMethod(dataMethods, ({ success, response }) => {
        if (success) {
          this.convertResponsePaymentConfig(response);
        }
        this.setState({
          applying: false,
        });
      });
    } else {
      await this.props.modifyPaymentConfigMethod(dataMethods, ({ success, response }) => {
        if (success) {
          this.convertResponsePaymentConfig(response);
        }
        this.setState({
          applying: false,
        });
      });
    }
  };

  handleClickAddNewRecord = () => {
    const { newItem, paymentValues, paymentMethods } = this.state;
    this.setState({
      newItem: {},
      paymentValues: paymentValues.filter(e => e.value !== newItem.method),
      paymentMethods: [{ ...newItem, index: this.lastIndex + 1 }, ...paymentMethods],
    });
    this.lastIndex += 1;
  };

  handleClickRemoveRecord = ({ itemSelect }) => {
    const { paymentMethods, paymentValues } = this.state;
    const { t } = this.props;
    const newPaymentMethods = cloneDeep(paymentMethods);
    const newPaymentValues = cloneDeep(paymentValues);
    newPaymentValues.push(t('selections:paymentMethod')().find(item => item.value === itemSelect.method));
    this.getLastIndex(paymentMethods.filter(e => e !== itemSelect));
    this.setState({
      selectedItem: {},
      paymentMethods: newPaymentMethods.filter(item => item.index !== itemSelect.index),
      paymentValues: newPaymentValues,
    });
  };

  render() {
    const { paymentMethods, selectedItem, newItem, paymentValues, applying } = this.state;
    const { modeModifyPaymentMethodConfig, modeCreatePaymentMethodConfig, t } = this.props;
    const tableConfig = {
      columns: [
        {
          name: 'method',
          label: 'common:label.method',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:paymentMethod')().find(val => val.value === item.method) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'description',
          label: 'common:label.description',
        },
        {
          name: 'Remove',
          label: 'common:label.remove',
          render: (colName, item) => (
            // <CheckGroup
            //   checked={!isEmpty(selectedItem) && selectedItem.index === item.index}
            //   name="selectItem"
            //   type="checkbox"
            //   onChange={() => this.onSelectItem(item)}
            // />
            <div className="form-group col-md-12">
              <button
                type="button"
                className="btn-trash"
                onClick={() => this.handleClickRemoveRecord({ itemSelect: item })}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          ),
        },
      ],
      data: paymentMethods.map(el => {
        return { ...el, ...paymentMethod.find(e => e.method === el.method) };
      }),
      getRowClass: item => {
        return item.index === (selectedItem && selectedItem.index) ? 'selected-row' : '';
      },
    };
    return (
      <div className="col-md-12 mb-30 form-container">
        <div className="card-body">
          <SubmitForm
            onSubmit={this.handleApplyBtn}
            isSubmitting={applying}
            modeModifyPaymentMethodConfig={modeModifyPaymentMethodConfig}
            submitText={t('label.apply')}
            isPaymentType
          >
            {modeCreatePaymentMethodConfig === 2 && (
              <div className="col-md-12 mb-4 mt-3 row">
                <SelectGroup
                  name="Method"
                  label="common:label.method"
                  options={paymentValues}
                  isClearable
                  wrapperClass="col-md-3 pl-2"
                  value={paymentValues.find(e => e && e.value === newItem.method)}
                  onChange={e => this.onChangeNewValue('method', e && e.value)}
                />
                <button
                  type="button"
                  disabled={isEmpty(newItem) || modeCreatePaymentMethodConfig === 1}
                  className="button button-border x-small float-right height-btn mt-4"
                  onClick={this.handleClickAddNewRecord}
                >
                  {`+ ${t('label.addPaymentMethod')}`}
                </button>
              </div>
            )}
            <div className="col-md-12">
              <DataTable {...tableConfig} onClickRow={this.onClickRow} />
            </div>
          </SubmitForm>
        </div>
      </div>
    );
  }
}

PaymentTypes.propTypes = {
  getPaymentConfigByType: PropTypes.func.isRequired,
  createPaymentConfigMethod: PropTypes.func.isRequired,
  modifyPaymentConfigMethod: PropTypes.func.isRequired,
  modeModifyPaymentMethodConfig: PropTypes.number,
  modeCreatePaymentMethodConfig: PropTypes.number,
};

PaymentTypes.defaultProps = {
  modeModifyPaymentMethodConfig: 0,
  modeCreatePaymentMethodConfig: 0,
};

export default withTranslation('common')(PaymentTypes);
